import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styles from "../scss/footer.module.scss"
import { FaFacebookF } from "react-icons/fa";
import { FaGithubAlt } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaWeibo } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Fa500Px } from "react-icons/fa";
import { FaSoundcloud } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


export default ({ children }) => {
  const ListLink = props => (
    <li className="nav-link">
      <Link to={props.to}>{props.children}</Link>
    </li>
  )

  const showYear = new Date().getFullYear()

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  )

  return (
    <footer className={styles.footer}>
      <ul className={styles.navCont}>
        <li>&copy; {showYear}</li>
        <li>{data.site.siteMetadata.author}</li>
        <ListLink to="/privacy/">Privacy</ListLink>
      </ul>
      <div className={styles.navSocial}>
      <a href="https://soundcloud.com/boboboboshan" target="_blank" rel="noopener noreferrer">
      <FaSoundcloud className={styles.i}/>
      </a>
      <a href="https://www.youtube.com/channel/UCuPL2de4vh3pRGx1VdjKscw" target="_blank" rel="noopener noreferrer">
      <FaYoutube className={styles.i}/>
      </a>
      <a href="https://www.linkedin.com/in/zhouboshan" target="_blank" rel="noopener noreferrer">
      <FaLinkedinIn className={styles.i}/>
      </a>
      <a href="https://www.facebook.com/boboboboboboshan" target="_blank" rel="noopener noreferrer">
      <FaFacebookF className={styles.i} />
      </a>
      <a href="https://github.com/boboshan" target="_blank" rel="noopener noreferrer">
      <FaGithubAlt className={styles.i}/>
      </a>
      <a href="https://twitter.com/bo_boshan" target="_blank" rel="noopener noreferrer">
      <FaTwitter className={styles.i}/>
      </a>
      <a href="https://www.weibo.com/6977002912" target="_blank" rel="noopener noreferrer">
      <FaWeibo className={styles.i}/>
      </a>
      <a href="https://www.instagram.com/boboboboshan/" target="_blank" rel="noopener noreferrer">
      <FaInstagram className={styles.i}/>
      </a>
      <a href="https://500px.com/boboboboshan" target="_blank" rel="noopener noreferrer">
      <Fa500Px className={styles.i}/>
      </a>
      </div>
    </footer>
  )
}
